import { Button, Checkbox, UIText2, Input, UIHeading2, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import { IUserSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { TStatusFetch } from 'shared/types/subscriptionPriceChangesPopup';

import { Agreements } from './Agreements';
import * as styles from './index.css';

export interface ISubscribeFormProps {
  user: IUserSchema | null;
  email: string;
  emailError: string;
  globalError: string;
  isSubscribeNews: boolean;
  statusFetch: TStatusFetch;
  onSubscribe(): void;
  changeEmail(_: React.ChangeEvent<HTMLInputElement>, value: string): void;
  changeSubscribeNews(_: React.ChangeEvent<HTMLInputElement>, value: boolean): void;
}

export const SubscribeForm: React.FC<ISubscribeFormProps> = ({
  user,
  email,
  emailError,
  globalError,
  isSubscribeNews,
  statusFetch,
  onSubscribe,
  changeEmail,
  changeSubscribeNews,
}) => (
  <div className={styles['form']}>
    <div className={styles['header']}>
      <UIHeading2>Рассказать, если цена поменяется?</UIHeading2>
      <div className={styles['subtitle']}>
        <UIText1>Пришлём письмо сразу, как&nbsp;обновится объявление</UIText1>
      </div>
    </div>
    <div>
      <Input invalid={!!emailError} onChange={changeEmail} value={email} placeholder="Email" size="M" />
      {Boolean(emailError || globalError) && (
        <div className={styles['error']}>
          <UIText2 color="text-negative-default">{emailError || globalError}</UIText2>
        </div>
      )}
    </div>
    <div className={styles['form-checkbox']}>
      <Checkbox label="Подписаться на&nbsp;новости Циана" checked={isSubscribeNews} onChange={changeSubscribeNews} />
    </div>
    <Button loading={statusFetch === 'isFetching'} theme="fill_primary" type="submit" fullWidth onClick={onSubscribe}>
      Да, давайте
    </Button>
    <Agreements user={user} />
  </div>
);
