import * as React from 'react';
import { useSelector } from 'react-redux';

import { MainDiscountPromo } from 'shared/components/MainDiscountPromo';
import { NewbuildingPromoDetailsContainer } from 'shared/containers/NewbuildingPromoDetails';
import { PromoIcon } from 'shared/containers/NewbuildingPromosSection/parts/PromoIcon';
import { selectAsideCompactMode } from 'shared/selectors/aside';
import { getNewbuildingPromos, getOffer } from 'shared/selectors/newObject';
import { trackClickPromoBlock } from 'shared/utils/tracking/promos/trackClickPromoBlock';

export const MainPromo = () => {
  const promos = useSelector(getNewbuildingPromos);
  const offer = useSelector(getOffer);
  const isCompactModeEnabled = useSelector(selectAsideCompactMode);

  const [open, setOpen] = React.useState(false);
  const promo = promos[0];

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handlePromoClick = React.useCallback(() => {
    setOpen(true);
    trackClickPromoBlock({ promo, offer, mainSidebarPromo: true });
  }, [promo, offer]);

  if (!promos.length || isCompactModeEnabled || promo.promoType !== 'discount') {
    return null;
  }

  return (
    <>
      <MainDiscountPromo promoName={promo.name} icon={<PromoIcon promo={promo} />} onClick={handlePromoClick} />
      <NewbuildingPromoDetailsContainer
        open={open}
        onClose={handleClose}
        promo={promo}
        offer={offer}
        placeType="side_bar"
      />
    </>
  );
};
