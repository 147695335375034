import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectIsPurchaseTermsEnabled } from 'shared/selectors/featureToggle';

import { PurchaseTermsModalLoadable } from './PurchaseTermsModalLoadable';
import { trackBlockClick, trackBlockShow } from './tracking';
import { PurchaseTerms } from '../../components/PurchaseTerms';
import { selectPurchaseTerms } from '../../selectors/purchaseTerms';

export const PurchaseTermsContainer: React.FC = () => {
  const isPurchaseTermsEnabled = useSelector(selectIsPurchaseTermsEnabled);

  const purchaseTerms = useSelector(selectPurchaseTerms);

  const [isOpen, setIsOpen] = React.useState(false);

  const onClick = React.useCallback(() => {
    trackBlockClick();
    setIsOpen(true);
  }, []);

  if (!isPurchaseTermsEnabled || !purchaseTerms) {
    return null;
  }

  return (
    <>
      <ActionAfterViewObserver callback={trackBlockShow} triggerOnce>
        <PurchaseTerms onClick={onClick} isModalOpen={isOpen} />
      </ActionAfterViewObserver>
      <PurchaseTermsModalLoadable isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
