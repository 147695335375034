import { Spinner, ModalWindow } from '@cian/ui-kit';
import parseISO from 'date-fns/parseISO';
import * as React from 'react';

import { IOfferCardStatistics } from 'shared/services/getOfferStats/types';

import { Chart } from './chart';
import * as styles from './index.css';
import { trackOfferStatsPopupShow } from './tracking';
import { EStatusFetch } from '../../store/offer_stats_popup';

export interface IOfferStatsPopupProps {
  dataStats: IOfferCardStatistics | null;
  isOpen: boolean;
  offerId: number;
  messageError: string;
  publicationDate: number;
  statusFetch: EStatusFetch;
}

export interface IOfferStatsPopupDispatchProps {
  offerStatsPopupClose(): void;
  submitOfferStats(id: number, publicationDate: number): void;
}

export type IProps = IOfferStatsPopupProps & IOfferStatsPopupDispatchProps;

export class OfferStatsPopup extends React.PureComponent<IProps, {}> {
  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.props.submitOfferStats(this.props.offerId, this.props.publicationDate);

      document.body.style.overflow = 'hidden';
    }

    if (!nextProps.isOpen) {
      document.body.style.overflow = '';
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      trackOfferStatsPopupShow();
    }
  }

  public render() {
    return (
      <ModalWindow
        title="Статистика просмотров за последние 10 дней"
        open={this.props.isOpen}
        onClose={this.props.offerStatsPopupClose}
        content={
          <>
            {this.props.statusFetch === EStatusFetch.isLoad && this.renderPreloader()}
            {this.props.statusFetch === EStatusFetch.error && this.renderError()}
            {this.props.statusFetch === EStatusFetch.ok && this.renderChart()}
          </>
        }
      />
    );
  }

  private renderPreloader = () => (
    <div className={styles['preloader']}>
      <Spinner size={24} />
    </div>
  );

  private renderError = () => <div className={styles['error']}>{this.props.messageError}</div>;

  private renderChart = () => {
    if (!this.props.dataStats) {
      return this.renderError();
    }

    const { dataStats } = this.props;

    return (
      <div>
        <div className={styles['information']}>
          <div>{dataStats.totalViews}</div>
          <div>{dataStats.daily.totalViews}</div>
          <div>{dataStats.uniqueViews}</div>
        </div>
        <div className={styles['chart']}>
          <Chart
            data={dataStats.daily.dailyViews.map(item => [parseISO(`${item.date}T00:00:00Z`).getTime(), item.views])}
          />
        </div>
      </div>
    );
  };
}
