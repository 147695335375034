import {
  PriceHistoryWidget,
  TDealType,
  IValuation,
  buildPriceHistory,
  IPriceHistoryDiff,
  IPriceHistoryEvent,
} from '@cian/price-history-change-widget';
import cx from 'clsx';
import * as React from 'react';

import { trackPriceHistoryPopupShow, trackPriceHistoryScroll, trackPriceHistoryScrolled } from './tracking';
import { PriceHistoryDownIcon } from '../../icons/price_history_down';
import { PriceHistoryUpIcon } from '../../icons/price_history_up';

const styles = require('./index.css');

export type TPopupDirection = 'left' | 'right' | 'center';
const PopupDirection = {
  CENTER: 'center' as TPopupDirection,
  LEFT: 'left' as TPopupDirection,
  RIGHT: 'right' as TPopupDirection,
};

export interface IPriceHistoryProps {
  direction?: TPopupDirection;
  events: IPriceHistoryEvent[];
  customURL: string;
  title: string;
  dealType: TDealType;
  valuation?: IValuation;
  priceHistoryUpIcon?: React.ReactElement;
  priceHistoryDownIcon?: React.ReactElement;
  withoutMargins?: boolean;
  isExperiment: boolean;
  showAuthenticationPopup(): void;
}

export interface IPriceHistoryState {
  events: IPriceHistoryDiff[];
  isTrackEventSent: boolean;
  isTrackScrollEvent: boolean;
  isTrackScrolledEvent: boolean;
}

export class PriceHistory extends React.Component<IPriceHistoryProps, IPriceHistoryState> {
  public constructor(props: IPriceHistoryProps) {
    super(props);

    this.state = {
      events: buildPriceHistory(props.events.slice().reverse()),
      isTrackEventSent: false,
      isTrackScrollEvent: false,
      isTrackScrolledEvent: false,
    };
  }

  public static defaultProps: Partial<IPriceHistoryProps> = {
    direction: PopupDirection.LEFT,
  };

  public static getDerivedStateFromProps(props: IPriceHistoryProps) {
    return {
      events: buildPriceHistory(props.events.slice().reverse()),
    };
  }

  public render() {
    const priceChanges = this.props.events;

    // Нет истории совсем или есть, но только начальная цена
    if (!priceChanges || priceChanges.length < 2) {
      return null;
    }

    const history = buildPriceHistory(priceChanges.slice().reverse());
    const trend = history[history.length - 1].trend;
    const priceHistoryUpIcon = this.props.priceHistoryUpIcon || <PriceHistoryUpIcon />;
    const priceHistoryDownIcon = this.props.priceHistoryDownIcon || <PriceHistoryDownIcon />;
    const withoutMargins = this.props.withoutMargins || false;

    return (
      <div
        className={cx(styles['container'], withoutMargins && styles['without-margins'])}
        onMouseEnter={this.handleMouseEnter}
        onClick={this.handleClick}
      >
        <div className={styles['icon']}>
          {this.renderPopupContainer()}
          {trend === 'increase' ? priceHistoryUpIcon : priceHistoryDownIcon}
        </div>
      </div>
    );
  }

  private trackPriceHistoryPopupShow() {
    if (!this.state.isTrackEventSent) {
      trackPriceHistoryPopupShow(this.props.customURL || '');
    }

    this.setState({
      isTrackEventSent: true,
    });
  }

  private renderPopupContainer() {
    const { direction } = this.props;

    if (this.props.isExperiment) {
      return null;
    }

    return (
      <div className={cx([styles['popup-container'], styles[`popup-container--${direction}`]])}>
        <div className={styles['popup']}>
          <div className={styles['popup-content']}>{this.renderPopup()}</div>
        </div>
      </div>
    );
  }

  private renderPopup() {
    return (
      <div data-testid="price-history-widget" className={styles['widget-container']}>
        <PriceHistoryWidget
          events={this.props.events.slice().reverse()}
          title={this.props.title}
          dealType={this.props.dealType}
          valuation={this.props.valuation}
          onScroll={this.handleScroll}
          onScrolled={this.handleScrolled}
        />
      </div>
    );
  }

  private handleScroll = () => {
    if (!this.state.isTrackScrollEvent) {
      trackPriceHistoryScroll(this.props.customURL || '');
    }

    this.setState({ isTrackScrollEvent: true });
  };

  private handleScrolled = () => {
    if (!this.state.isTrackScrolledEvent) {
      trackPriceHistoryScrolled(this.props.customURL || '');
    }

    this.setState({ isTrackScrolledEvent: true });
  };

  private handleMouseEnter = () => {
    if (!this.props.isExperiment) {
      this.trackPriceHistoryPopupShow();
    }
  };

  private handleClick = () => {
    if (this.props.isExperiment) {
      this.props.showAuthenticationPopup();
    }
  };
}
