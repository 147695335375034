import { Dispatch } from '@reduxjs/toolkit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { requestAuthentication } from 'shared/actions/authentication';
import { selectIsAuthenticated } from 'shared/selectors';
import { selectPriceClosedAuthExperimentEnabled } from 'shared/selectors/abExperiments';
import { IThunkActionCreator } from 'shared/store';

import { trackSubscribePopupOpen } from './tracking';
import { PriceChangingSubscription } from '../../components/PriceChangingSubscription/PriceChangingSubscription';
import { selectIsUserSubscribedToPriceChange } from '../../selectors/price/selectIsUserSubscribedToPriceChange';
import { selectIsUserSubscriptionToPriceChangeEnabled } from '../../selectors/price/selectIsUserSubscriptionToPriceChangeEnabled';
import { subscriptionPriceChangesPopupOpen } from '../../store/subscription_price_changes_popup';
import { SubscriptionPriceChangesContainer } from '../SubscriptionPriceChangesContainer';

export const PriceChangesContainer = () => {
  const dispatch = useDispatch();
  const isSubscribed = useSelector(selectIsUserSubscribedToPriceChange);
  const isEnabled = useSelector(selectIsUserSubscriptionToPriceChangeEnabled);
  const isPriceClosedAuthExperimentEnabled = useSelector(selectPriceClosedAuthExperimentEnabled);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const isPriceClosedAuthExperiment = React.useMemo(
    () => isPriceClosedAuthExperimentEnabled && !isAuthenticated && !isSubscribed,
    [isPriceClosedAuthExperimentEnabled, isAuthenticated, isSubscribed],
  );

  const handleSubscriptionClick = React.useCallback(() => {
    if (isPriceClosedAuthExperiment) {
      dispatch(
        requestAuthentication({
          source: 'change_price',
          viewData: { headingType: 'monitorPriceChanges' },
          onSuccess: (): IThunkActionCreator => (dispatch: Dispatch) => {
            dispatch(subscriptionPriceChangesPopupOpen());
          },
        }),
      );
    } else {
      trackSubscribePopupOpen();
      dispatch(subscriptionPriceChangesPopupOpen());
    }
  }, [isAuthenticated, isSubscribed]);

  if (!isEnabled) {
    return null;
  }

  return (
    <>
      <PriceChangingSubscription
        label={isSubscribed ? 'Отменить подписку на цену' : 'Следить за изменением цены'}
        onClick={handleSubscriptionClick}
      />
      <SubscriptionPriceChangesContainer />
    </>
  );
};
