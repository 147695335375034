import { ECheckDailyrentPriceActionTypes } from 'shared/actions/checkDailyrentPrice/types';
import { ResponseError } from 'shared/errors/responseError';
import { ICheckPriceRequest, ICheckPriceResponse } from 'shared/repositories/dailyrent/v2/check-price';
import { selectOfferId } from 'shared/selectors/offerData/offer/selectOfferId';
import { fetchCheckPrice } from 'shared/services/fetchCheckPrice';
import { DEFAULT_ERROR_MESSAGE } from 'shared/services/fetchGetOfferCalendar';
import { IThunkActionCreator } from 'shared/store';
import { actionGenerator } from 'shared/utils/redux/actionGenerator';

const setCheckDailyrentPriceLoading = actionGenerator<ECheckDailyrentPriceActionTypes.Loading, boolean>(
  ECheckDailyrentPriceActionTypes.Loading,
);
const setCheckDailyrentPriceSuccess = actionGenerator<ECheckDailyrentPriceActionTypes.Success, ICheckPriceResponse>(
  ECheckDailyrentPriceActionTypes.Success,
);
const setCheckDailyrentPriceFail = actionGenerator<ECheckDailyrentPriceActionTypes.Fail, string>(
  ECheckDailyrentPriceActionTypes.Fail,
);

export const checkDailyrentPrice =
  ({ dates, guestsCount }: Omit<ICheckPriceRequest, 'offerRealtyId'>): IThunkActionCreator<Promise<void | string>> =>
  async (dispatch, getState, context) => {
    const state = getState();
    const offerRealtyId = selectOfferId(state);
    dispatch(setCheckDailyrentPriceLoading(true));

    try {
      const response = await fetchCheckPrice(context, {
        offerRealtyId,
        dates,
        guestsCount,
      });

      dispatch(setCheckDailyrentPriceSuccess(response));
    } catch (error) {
      let errorMessage = DEFAULT_ERROR_MESSAGE;

      const isKnownError = ResponseError.is(error);
      const errorCode =
        isKnownError && error.details && 'code' in error.details && typeof error.details.code === 'string'
          ? error.details.code
          : '';

      if (['minStay', 'minStayArrival', 'isBooked'].includes(errorCode)) {
        errorMessage = error.message;
      }

      dispatch(setCheckDailyrentPriceFail(errorMessage));

      return errorMessage;
    } finally {
      dispatch(setCheckDailyrentPriceLoading(false));
    }

    return;
  };
