import * as React from 'react';
import { useSelector } from 'react-redux';

import { QuickReplyStatus } from 'shared/components/QuickReplyStatus';
import { isNewbuildingFromDeveloperSelector } from 'shared/selectors';
import { offerChatSelector } from 'shared/selectors/offerChat';
import { selectIsHighResponsibility } from 'shared/selectors/offerData/selectIsHighResponsibility';

import { OnlineStatus } from '../../components/OnlineStatus';

export const OnlineStatusContainer: React.FC = () => {
  const { isChatsEnabled, isOnline } = useSelector(offerChatSelector);
  const isHighResponsibility = useSelector(selectIsHighResponsibility);
  const isNewbuildingFromDeveloper = useSelector(isNewbuildingFromDeveloperSelector);

  if (isChatsEnabled && isHighResponsibility && !isOnline) {
    return <QuickReplyStatus />;
  }

  if (!isChatsEnabled || !isOnline || isNewbuildingFromDeveloper) {
    return null;
  }

  return <OnlineStatus />;
};
