import { ArticleParagraph1, UIHeading3, UIText1 } from '@cian/ui-kit';
import React from 'react';

import { MAX_VALUE, MIN_VALUE } from 'shared/constants/guests';
import { pluralize } from 'shared/utils/pluralize';

import * as styles from './styles.css';
import { NumberInputWithButtons } from '../../../../packages/NumberInputWithButtons';

interface IGuestsModalContentProps {
  disabled: boolean;
  value: number;
  minValue: number;
  maxValue: number;
  needFormatValue?: boolean;
  onChange(value: number, mode: 'increase' | 'decrease' | 'manual'): void;
}

export function GuestsModalContent({
  value,
  minValue,
  maxValue,
  onChange,
  disabled,
  needFormatValue = false,
}: IGuestsModalContentProps) {
  const min = minValue || MIN_VALUE;
  const max = maxValue || MAX_VALUE;

  const formatValue = React.useCallback((value: number | undefined) => {
    const safeValue = value || min;

    return `${safeValue} ${pluralize(safeValue, ['гость', 'гостя', 'гостей'])}`;
  }, []);

  return (
    <div className={styles['wrapper']}>
      <div className={styles['text']}>
        <UIHeading3>Гости</UIHeading3>
        <div className={styles['max']}>
          <UIText1>Максимум: {max}</UIText1>
        </div>
      </div>
      <div className={styles['input-row']}>
        <div className={styles['label']}>
          <ArticleParagraph1>Взрослые и дети</ArticleParagraph1>
        </div>
        <NumberInputWithButtons
          value={value}
          valueFormatter={needFormatValue ? formatValue : undefined}
          onChange={onChange}
          min={min}
          max={max}
          size="M"
          disabled={disabled}
          fieldTestId="guests-input-field"
          plusTestId="guests-input-plus"
          minusTestId="guests-input-minus"
        />
      </div>
    </div>
  );
}
