import { ActionLink, UIHeading4 } from '@cian/ui-kit';
import { IconActionChevronRightSmall16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './PurchaseTerms.css';
import { PurchaseTermsOnboarding } from '../../containers/PurchaseTermsContainer/PurchaseTermsOnboarding';

interface IPurchaseTermsProps {
  onClick: React.MouseEventHandler;
  isModalOpen: boolean;
}

export const PurchaseTerms: React.FC<IPurchaseTermsProps> = ({ onClick, isModalOpen }) => {
  return (
    <div className={styles['container']}>
      <UIHeading4>100% оплата</UIHeading4>
      <PurchaseTermsOnboarding isModalOpen={isModalOpen}>
        <ActionLink
          onClick={onClick}
          afterIcon={<IconActionChevronRightSmall16 color="icon-main-default" />}
          fontSize="M"
          fontWeight="bold"
          data-testid="PurchaseTermsButton"
        >
          Ещё способы покупки
        </ActionLink>
      </PurchaseTermsOnboarding>
    </div>
  );
};
