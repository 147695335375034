import { useCallback, useEffect, useState } from 'react';

import { useApplicationContext } from 'shared/containers/ApplicationContext';

import { getOnboardingCookie, setOnboardingCookie } from '../utils/cookie';

interface IOnboardingTooltipResult {
  open: boolean;
  closeHandler: () => void;
}

interface IOnboardingTooltipProps {
  isModalOpen: boolean;
}

const COOKIE_NAME = 'purchase_terms_onboarding_shown';
const ONBOARDING_SHOW_DELAY = 1000;
const ONBOARDING_HIDE_DELAY = 11000;

export const usePurchaseTermsOnboarding = ({ isModalOpen }: IOnboardingTooltipProps): IOnboardingTooltipResult => {
  const { config } = useApplicationContext();

  const [open, setOpen] = useState(false);

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (!getOnboardingCookie(COOKIE_NAME)) {
      setTimeout(() => setOpen(true), ONBOARDING_SHOW_DELAY);
      setTimeout(() => setOpen(false), ONBOARDING_HIDE_DELAY);

      setOnboardingCookie(COOKIE_NAME);
    }
  }, [config]);

  useEffect(() => {
    if (open && isModalOpen) {
      closeHandler();
    }
  }, [closeHandler, isModalOpen, open]);

  return {
    open,
    closeHandler,
  };
};
