import {
  DynamicCalltrackingButton as CalltrackingButton,
  IDynamicCalltrackingButtonProps,
  EPageTypes,
  EPlatformTypes,
} from '@cian/frontend-newbuilding-call-component';
import cx from 'clsx';
import * as React from 'react';

import { useApplicationContext } from 'shared/containers/ApplicationContext';

import * as styles from './DynamicCalltrackingButton.css';

type TDynamicCalltrackingButtonProps = Omit<
  IDynamicCalltrackingButtonProps,
  'pageType' | 'platformType' | 'httpApi' | 'logger'
>;

export function DynamicCalltrackingButton({
  buttonFullWidth = true,
  openPhoneClassName,
  buttonSize = 'M',
  ...otherProps
}: TDynamicCalltrackingButtonProps) {
  const { httpApi, logger } = useApplicationContext();

  return (
    <CalltrackingButton
      buttonFullWidth={buttonFullWidth}
      buttonSize={buttonSize}
      httpApi={httpApi}
      logger={logger}
      openPhoneClassName={cx(styles['phone'], styles[`phone-size-${buttonSize}`], openPhoneClassName)}
      pageType={EPageTypes.OfferCard}
      platformType={EPlatformTypes.WebDesktop}
      {...otherProps}
    />
  );
}
