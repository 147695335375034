import { IconChevronDownSmall16, IconChevronUpSmall16, IconUser16, UIText1 } from '@cian/ui-kit';
import cx from 'clsx';
import React from 'react';

import { getSafeGuestsCount } from 'shared/utils/getSafeGuestsCount';
import { pluralize } from 'shared/utils/pluralize';

import * as styles from './styles.css';

interface IGuestsControlButtonProps {
  isActive: boolean;
  value: number;
  minValue: number;
  maxValue: number;
  disabled: boolean;
  onClick(): void;
}

// Ref нужен для ui-kit компонента DropdownPopover, иначе ошибки в React дереве рендеринга
export const GuestsControlButton = React.forwardRef<HTMLDivElement, IGuestsControlButtonProps>(
  ({ isActive, value, minValue, maxValue, onClick, disabled }, ref) => {
    const safeValue = getSafeGuestsCount(value, minValue, maxValue);

    return (
      <div
        className={cx(styles['wrapper'], disabled && styles['disabled'], isActive && styles['active'])}
        onClick={disabled ? undefined : onClick}
        ref={ref}
        data-testid="guests-control-button"
        data-is-disabled={disabled}
      >
        <div className={styles['icon']}>
          <IconUser16 color="primary_100" />
        </div>
        <div className={styles['text']}>
          <UIText1>{pluralize(safeValue, ['гость', 'гостя', 'гостей'])}</UIText1>
        </div>
        <div className={styles['chevron']}>
          {isActive ? <IconChevronUpSmall16 color="primary_100" /> : <IconChevronDownSmall16 color="primary_100" />}
        </div>
      </div>
    );
  },
);

GuestsControlButton.displayName = 'GuestsControlButton';
