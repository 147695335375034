import { UIHeading1 } from '@cian/ui-kit';
import clsx from 'clsx';
import * as React from 'react';

import * as styles from './NewbuildingPriceInfo.css';

interface INewbuildingPriceInfoProps {
  price: string;
  priceHistory: React.ReactElement | null;
  labels?: React.ReactElement | null;
  discount?: React.ReactElement | null;
  purchaseTerms?: React.ReactElement | null;
  isCompactModeEnabled?: boolean;
}

export const NewbuildingPriceInfo = ({
  price,
  priceHistory,
  labels,
  discount,
  isCompactModeEnabled,
  purchaseTerms,
}: INewbuildingPriceInfoProps) => {
  return (
    <div className={styles['price']}>
      {!isCompactModeEnabled && <div className={clsx(styles['discount'], styles['hide-if-empty'])}>{discount}</div>}
      <div data-testid="price-amount" className={styles['amount']}>
        <UIHeading1>{price}</UIHeading1>
      </div>
      <div data-testid="price-history-button" className={styles['history']}>
        {priceHistory}
      </div>
      <div className={styles['labels']}>{labels}</div>
      {isCompactModeEnabled && (
        <div className={clsx(styles['discount'], styles['inline'], styles['hide-if-empty'])}>{discount}</div>
      )}
      <div className={clsx(styles['purchase-terms'], styles['hide-if-empty'])}>{purchaseTerms}</div>
    </div>
  );
};
