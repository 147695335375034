import { Modal } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  SubscribeForm,
  SubscribeSuccess,
  UnsubscribeError,
  UnsubscribeForm,
  UnsubscribeSuccess,
} from 'shared/components/SubscriptionPriceChanges';
import { offerDataSelector } from 'shared/selectors';
import { selectIsUserSubscribedToPriceChange } from 'shared/selectors/price/selectIsUserSubscribedToPriceChange';
import { subscriptionPriceChangesSelector } from 'shared/selectors/subscriptionPriceChanges';
import {
  submitSubscriptionPriceChanges,
  submitUnsubscriptionPriceChanges,
  subscriptionPriceChangesPopupClose,
} from 'shared/store/subscription_price_changes_popup';
import { isEmail, isFakeEmail } from 'shared/utils/validate';

export const SubscriptionPriceChangesContainer: React.FC = () => {
  const dispatch = useDispatch();
  const subscription = useSelector(selectIsUserSubscribedToPriceChange);
  const { isOpen, error, statusFetch } = useSelector(subscriptionPriceChangesSelector);
  const {
    user,
    offer: { cianId },
  } = useSelector(offerDataSelector);

  const [email, setEmail] = React.useState<string>('');
  const [emailError, setEmailError] = React.useState<string>('');
  const [globalError, setGlobalError] = React.useState<string>('');
  const [isSubscribed, setSubscribed] = React.useState<boolean>(subscription);
  const [isSubscribeNews, setSubscribeNews] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (error) {
      setGlobalError(error);
    }

    if (isOpen) {
      if (user && user.isAuthenticated && !email) {
        setEmail(user.email && !isFakeEmail(user.email) ? user.email : '');
      }
    } else {
      setEmail('');
      setEmailError('');
      setGlobalError('');
      setSubscribed(subscription);
    }
  }, [error, isOpen, user]);

  const handleEmailChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setEmail(value);
      setEmailError('');
      setGlobalError('');
    },
    [setEmail, setEmailError, setGlobalError],
  );

  const handleSubscribeNewsChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      setSubscribeNews(value);
    },
    [setSubscribeNews],
  );

  const handleSubscribe = React.useCallback(() => {
    const trimmedEmail = email.trim();

    if (!trimmedEmail) {
      setEmailError('Укажите почту');

      return;
    }

    if (!isEmail(trimmedEmail)) {
      setEmailError('Неправильный формат почты');

      return;
    }

    dispatch(submitSubscriptionPriceChanges(trimmedEmail, cianId, isSubscribeNews));
  }, [dispatch, email, isSubscribeNews]);

  const handleUnsubscribe = React.useCallback(() => {
    dispatch(submitUnsubscriptionPriceChanges(cianId));
  }, [dispatch]);

  const handleClose = React.useCallback(() => {
    dispatch(subscriptionPriceChangesPopupClose());
  }, [dispatch]);

  const renderSubscribeContent = () => {
    if (statusFetch === 'ok') {
      return <SubscribeSuccess email={email} onClick={handleClose} />;
    } else {
      return (
        <SubscribeForm
          user={user}
          email={email}
          emailError={emailError}
          globalError={globalError}
          isSubscribeNews={isSubscribeNews}
          statusFetch={statusFetch}
          onSubscribe={handleSubscribe}
          changeEmail={handleEmailChange}
          changeSubscribeNews={handleSubscribeNewsChange}
        />
      );
    }
  };

  const renderUnsubscribeContent = () => {
    if (statusFetch === 'ok') {
      return <UnsubscribeSuccess onClick={handleClose} />;
    }
    if (statusFetch === 'error') {
      return <UnsubscribeError error={error} onClick={handleClose} />;
    }

    return <UnsubscribeForm email={email} onUnsubscribe={handleUnsubscribe} />;
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      size="S"
      onOpenChange={handleClose}
      body={isSubscribed ? renderUnsubscribeContent() : renderSubscribeContent()}
    />
  );
};
