import { ca } from '@cian/analytics';

import { EPromoType } from '../../types/newbuildingPromos';
import { TPurchaseOptionType } from '../../types/purchaseTerm';

export const trackBlockShow = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card',
    action: 'show_sopr',
    label: 'different_prices_block',
    useLastProducts: true,
  });
};

export const trackBlockClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card',
    action: 'click_sopr',
    label: 'different_prices_block',
    useLastProducts: true,
  });
};

interface ITrackPurchaseTermsModalShow {
  types: TPurchaseOptionType[];
  promoId?: string;
  isDiscountPromo?: boolean | null;
}

interface ITrackPurchaseTermsModalShowExtra {
  type: TPurchaseOptionType[];
  promo?: {
    promo_id: string;
    is_price_with_discount: boolean;
  };
}

export const trackModalShow = ({ types, promoId, isDiscountPromo }: ITrackPurchaseTermsModalShow) => {
  const extra: ITrackPurchaseTermsModalShowExtra = {
    type: types,
  };

  /* istanbul ignore else */
  if (promoId) {
    extra.promo = {
      promo_id: promoId,
      is_price_with_discount: Boolean(isDiscountPromo),
    };
  }

  ca('eventSite', {
    name: 'oldevent',
    category: 'Card',
    action: 'show_sopr',
    label: 'different_prices',
    useLastProducts: true,
    page: {
      extra,
    },
  });
};

interface ITrackPurchaseTermClick {
  type: TPurchaseOptionType;
}

export const trackPurchaseTermClick = ({ type }: ITrackPurchaseTermClick) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card',
    action: 'show_sopr',
    label: 'open_modal_payment_type',
    useLastProducts: true,
    page: {
      extra: {
        type,
      },
    },
  });
};

interface ITrackPromoModalShow {
  promoType: EPromoType;
  promoId: string;
}

export function trackPromoClick({ promoType, promoId }: ITrackPromoModalShow) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Promo',
    action: 'click_block',
    label: promoType,
    products: [
      {
        extra: {
          promoid: promoId,
          place_type: 'different_price',
          is_price_with_discount: promoType === EPromoType.Discount,
        },
      },
    ],
  });
}

interface ITrackCallButtonClickParams {
  gaLabel: string;
  modalType: string;
}

export function trackCallButtonClick({ gaLabel, modalType }: ITrackCallButtonClickParams) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Phones',
    action: 'Open_difference_price',
    label: gaLabel,
    useLastProducts: true,
    page: {
      extra: {
        type: modalType,
      },
    },
  });
}
