import { Button, UIHeading2, UIText1, Image } from '@cian/ui-kit';
import * as React from 'react';

import Warning from './assets/Warning.png';
import * as styles from './index.css';

export interface IUnsubscribeFormProps {
  email: string;
  onUnsubscribe(): void;
}

export const UnsubscribeForm: React.FC<IUnsubscribeFormProps> = ({ email, onUnsubscribe }) => (
  <div className={styles['container']}>
    <Image src={Warning} objectFit="cover" width="100px" height="100px" />
    <div className={styles['title']}>
      <UIHeading2>Отменить подписку на&nbsp;изменения&nbsp;цены?</UIHeading2>
    </div>
    <div className={styles['description']}>
      <UIText1 color="black_60">Удалим {email} из&nbsp;рассылки</UIText1>
    </div>
    <Button type="submit" theme="fill_primary" size="M" onClick={onUnsubscribe}>
      Да, отменить
    </Button>
  </div>
);
