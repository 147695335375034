import { Button, UIHeading2, UIText1, Image } from '@cian/ui-kit';
import * as React from 'react';

import Subscribe from './assets/Subscribe.png';
import * as styles from './index.css';

export interface ISubscribeSuccessProps {
  email: string;
  onClick(): void;
}

export const SubscribeSuccess: React.FC<ISubscribeSuccessProps> = ({ email, onClick }) => (
  <div className={styles['container']}>
    <Image src={Subscribe} objectFit="cover" width="125px" height="108px" />
    <div className={styles['title']}>
      <UIHeading2>Подтвердите почту, чтобы&nbsp;получать&nbsp;уведомления</UIHeading2>
    </div>
    <div className={styles['description']}>
      <UIText1 color="black_60">Прислали письмо на&nbsp;{email}</UIText1>
    </div>
    <Button type="submit" theme="fill_primary" size="M" onClick={onClick}>
      Хорошо
    </Button>
  </div>
);
