import * as React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { useSelector } from 'react-redux';

import { AdfoxBanner } from 'shared/components/AdfoxBanner';
import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { TAdfoxContextData, AdfoxContext } from 'shared/context/AdfoxContext';
import { useAdfoxPuids } from 'shared/context/AdfoxContext/internal/hooks/useAdfoxPuids';
import { selectUser } from 'shared/selectors';
import { selectIsNewbuildingPremiumRate } from 'shared/selectors/newbuildingPremiumRate';
import { IPParams } from 'shared/types/adfox';

const POST_VIEW_ANALYTICS_DATA: TAdfoxContextData['postViewAnalyticsData'] = {
  place: 'above_header',
  place__DEPRECATED: 'offerCard',
  name__DEPRECATED: 'StretchBanner',
};
const P_PARAMS: IPParams = { p1: 'bvknz', p2: 'y' };
/**
 * @todo Удалить строку с RS
 * @description Данный функционал появился в задаче CD-223639, будет удалён в задаче CD-223640
 */
const P_PARAMS_EXPERIMENTAL_FOR_ADFOX: IPParams = { p1: 'dfaig', p2: 'y' };

const SIZE: TAdfoxContextData['size'] = { width: '100%', minHeight: '200px' };

export const StretchBannerContainer: React.FC = () => {
  const stretchBannerRoot = React.useRef<Root | null>(null);
  const elementRef = React.useRef<HTMLElement | null>(null);

  const { config } = useApplicationContext();

  const user = useSelector(selectUser);

  /**
   * @todo Удалить строку с RS
   * @description Данный функционал появился в задаче CD-223639, будет удалён в задаче CD-223640
   * Не забыть удалить из src/node/scheme.json
   */
  const martechExperimentalStretchBannerUserId = config.get<number>('Martech.ExperimentalStretchBanner.UserId');

  const userId = user?.cianUserId;

  const params = useAdfoxPuids(
    userId && martechExperimentalStretchBannerUserId && martechExperimentalStretchBannerUserId === userId
      ? P_PARAMS_EXPERIMENTAL_FOR_ADFOX
      : P_PARAMS,
  );

  const isPremiumNewbuilding = useSelector(selectIsNewbuildingPremiumRate);

  const adfoxContextProviderValue = React.useMemo(
    (): TAdfoxContextData => ({
      postViewAnalyticsData: POST_VIEW_ANALYTICS_DATA,
      size: SIZE,
      params,
      isHidden: isPremiumNewbuilding,
    }),
    [isPremiumNewbuilding, params],
  );

  React.useEffect(() => {
    /* istanbul ignore next */
    if (!stretchBannerRoot.current) {
      const el = document.getElementById('adfox-stretch-banner');

      /* istanbul ignore next */
      if (el) {
        elementRef.current = el;
        stretchBannerRoot.current = createRoot(el);

        stretchBannerRoot.current.render(
          <AdfoxContext.Provider value={adfoxContextProviderValue}>
            <AdfoxBanner />
          </AdfoxContext.Provider>,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
