import { Button } from '@cian/ui-kit';
import { TButtonSize } from '@cian/ui-kit/button/types';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { requestAuthentication } from 'shared/actions/authentication';
import { PopupOwnOfferContainer } from 'shared/components/popups/own_offer/container';
import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { useGetIsAuthenticationPopupEnabled } from 'shared/hooks/useGetIsAuthenticationPopupEnabled';
import { useSimpleBotDetector } from 'shared/hooks/useSimpleBotDetector';
import {
  selectIsOfferPublished,
  selectOffer,
  getPhones,
  getAgentAvailability,
  selectUser,
  getVillageId,
  selectIsFromSuburbanBuilder,
} from 'shared/selectors';
import { getDynamicCalltrackingSiteBlockId } from 'shared/selectors/getDynamicCalltrackingSiteBlockId';
import { isRenterFormEnabled } from 'shared/selectors/isRenterFormEnabled';
import { selectIsChatsEnabled } from 'shared/selectors/offerChat';
import { getRosreestrInfo } from 'shared/selectors/offerData/getRosreestrInfo';
import { isNewbuildingFromDeveloperSelector } from 'shared/selectors/offerData/offer/isNewbuildingFromDeveloperSelector';
import { isPrintVersionPhoneVisibilitySelector } from 'shared/selectors/print';
import { selectAgent } from 'shared/selectors/selectAgent';
import { prefetchFormState } from 'shared/store/deal-rent';
import { setNotAvailablePopupOpenValue } from 'shared/store/notAvailablePopupOpen';
import { setPhoneCollapse } from 'shared/store/phone_collapse';
import { getPhoneButtonText } from 'shared/utils/getPhoneButtonText';

import { trackShowPhoneClick } from './tracking';
import { PhoneContainer } from '../PhoneContainer';

interface IPhonesContainerProps {
  size?: TButtonSize;
  trackingAction?: string;
  phonesShown: boolean;
  onPhoneClick(): void;
}

export const PhonesContainer: React.FC<React.PropsWithChildren<IPhonesContainerProps>> = ({
  size = 'M',
  trackingAction,
  onPhoneClick,
  phonesShown,
}) => {
  const dispatch = useDispatch();
  const { logger } = useApplicationContext();
  const isPublished = useSelector(selectIsOfferPublished);
  const offer = useSelector(selectOffer);
  const phones = useSelector(getPhones);
  const isChatsEnabled = useSelector(selectIsChatsEnabled);
  const isDealRentFormEnabled = useSelector(isRenterFormEnabled);
  const agent = useSelector(selectAgent);
  const agentAvailability = useSelector(getAgentAvailability);
  const user = useSelector(selectUser);
  const siteBlockId = useSelector(getDynamicCalltrackingSiteBlockId);
  const rosreestrInfo = useSelector(getRosreestrInfo);
  const villageId = useSelector(getVillageId);
  const isNewbuildingFromBuilder = useSelector(isNewbuildingFromDeveloperSelector);
  const isSuburbanFromBuilder = useSelector(selectIsFromSuburbanBuilder);
  const buttonContent = getPhoneButtonText({
    isDealRent: isDealRentFormEnabled,
    isNewbuildingFromBuilder,
    isSuburbanFromBuilder,
  });
  const isPrintVersion = useSelector(isPrintVersionPhoneVisibilitySelector);
  const botDetector = useSimpleBotDetector();
  const isAuthenticationPopupEnabled = useGetIsAuthenticationPopupEnabled();

  const isAgentNotAvailable = agentAvailability && !agentAvailability.available;
  const [isOwnOfferPopupOpen, setOwnOfferPopupOpen] = React.useState(false);

  const startChat = () => {
    const isOfferOwnedByUser = Boolean(user && user.isAuthenticated && user.realtyUserId === offer.userId);
    const canShowAvailability = isChatsEnabled && isAgentNotAvailable;

    if (!canShowAvailability) {
      return;
    }

    switch (true) {
      case isOfferOwnedByUser:
        return setOwnOfferPopupOpen(true);
      default:
        dispatch(setNotAvailablePopupOpenValue(true));
    }
  };

  const ownOfferPopupClose = () => setOwnOfferPopupOpen(false);

  const onShowPhoneClick = () => {
    if (isDealRentFormEnabled) {
      dispatch(prefetchFormState());

      return;
    }

    onPhoneClick();
    dispatch(setPhoneCollapse());
  };

  const trackShowPhone = () => {
    const isAgentNotAvailable = agentAvailability && !agentAvailability.available;

    trackShowPhoneClick({
      trackingAction,
      offer,
      agent,
      user,
      kpId: villageId,
      bot: !botDetector.isHuman(),
      isAgentNotAvailable,
      siteBlockId,
      rosreestrInfo,
    });
  };

  const handleButtonClick: VoidFunction = () => {
    trackShowPhone();
    onShowPhoneClick();
    startChat();
  };

  const handleOpenAuth = React.useCallback(() => {
    if (user && !user.isAuthenticated && isAuthenticationPopupEnabled) {
      dispatch(
        requestAuthentication({
          source: 'phone_blocker',
          viewData: { headingType: 'seePhoneNumber' },
          onSuccess: () => () => handleButtonClick(),
        }),
      );
    } else {
      handleButtonClick();
    }
  }, [dispatch, user, isAuthenticationPopupEnabled]);

  if (!isPublished) {
    return null;
  }

  if (!phones.length || phones.some(phone => !phone.countryCode || !phone.number)) {
    logger.error(`Offer phones is null`, { cianId: offer.cianId });

    return null;
  }

  return (
    <div>
      {(phonesShown || isPrintVersion) && <PhoneContainer placeType="ContactsBottom" />}
      {!phonesShown && !isPrintVersion && (
        <Button theme="fill_primary" size={size} onClick={handleOpenAuth} fullWidth>
          {buttonContent}
        </Button>
      )}
      <PopupOwnOfferContainer isOpen={isOwnOfferPopupOpen} onClose={ownOfferPopupClose} />
    </div>
  );
};
