import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectOffer } from 'shared/selectors';
import { selectIsHidePhoneNumberExperiment } from 'shared/selectors/abExperiments';
import { selectHidingPhoneNumberEnabled, selectHidingPhoneNumberRegions } from 'shared/selectors/featureToggle';
import { regionIdSelector } from 'shared/selectors/offerData/geo/regionIdSelector';
import { isHomeowner } from 'shared/utils/agent';
import { getDealType, isRent, isSale } from 'shared/utils/deal_type';
import { getOfferType, isFlat, isOldbuilding } from 'shared/utils/offer_type';

export function useGetIsAuthenticationPopupEnabled(): boolean {
  const offer = useSelector(selectOffer);
  const region = useSelector(regionIdSelector);
  const isExperimentEnabled = useSelector(selectIsHidePhoneNumberExperiment);
  const isHidingPhoneNumberEnabled = useSelector(selectHidingPhoneNumberEnabled);
  const hidingPhoneNumberRegions = useSelector(selectHidingPhoneNumberRegions);

  const offerType = getOfferType(offer);
  const dealType = getDealType(offer);

  return useMemo(
    () =>
      isHidingPhoneNumberEnabled &&
      isExperimentEnabled &&
      hidingPhoneNumberRegions.some(id => id === region) &&
      isHomeowner(offer) &&
      isFlat(offerType) &&
      isOldbuilding(offerType) &&
      (isSale(dealType) || isRent(dealType)),
    [isHidingPhoneNumberEnabled, isExperimentEnabled, region, offer, offerType, dealType],
  );
}
