import { Button, UIHeading2, UIText1, Image } from '@cian/ui-kit';
import * as React from 'react';

import StopSubscription from './assets/StopSubscription.png';
import * as styles from './index.css';

export interface IUnsubscribeSuccessProps {
  onClick(): void;
}

export const UnsubscribeSuccess: React.FC<IUnsubscribeSuccessProps> = ({ onClick }) => (
  <div className={styles['container']}>
    <Image src={StopSubscription} objectFit="cover" width="100px" height="100px" />
    <div className={styles['title']}>
      <UIHeading2>Подписка на&nbsp;изменения цены отменена</UIHeading2>
    </div>
    <div className={styles['description']}>
      <UIText1 color="black_60">Если передумаете — можно подписаться заново</UIText1>
    </div>
    <Button type="submit" theme="fill_primary" size="M" onClick={onClick}>
      Хорошо
    </Button>
  </div>
);
