import { connect, IAdFoxBannerOnErrorError } from '@cian/adfox-component';
import { withAdfoxPostViewAnalytics } from '@cian/postview-analytics-utils';
import React, { JSX, memo, useCallback, useMemo, useState } from 'react';

import { useAdfoxContext } from 'shared/context/AdfoxContext';

import { OwnTemplates } from '../adfox/templates/OwnTemplates';

const AdfoxBannerConnected = connect(OwnTemplates);

type TAdFoxBannerProps = {
  position?: number;
  galleryPosition?: number;
};

export const AdfoxBanner = memo<TAdFoxBannerProps>(function AdfoxBanner(props) {
  const { position, galleryPosition } = props;

  const { size, params, postViewAnalyticsData, isHidden: isAdfoxBannerHidden } = useAdfoxContext();

  /**
   * @description для премиум тарифа не должны показывать рекламные баннеры
   */
  const [isHidden, setIsHidden] = useState<boolean>(isAdfoxBannerHidden);

  const handleStub = useCallback<VoidFunction>(() => {
    setIsHidden(true);
  }, []);

  const handleError = useCallback<(error: IAdFoxBannerOnErrorError) => void>(() => {
    setIsHidden(true);
  }, []);

  return useMemo<JSX.Element | null>(() => {
    if (isHidden) {
      return null;
    }

    const Banner = withAdfoxPostViewAnalytics(AdfoxBannerConnected, {
      ...postViewAnalyticsData,
      galleryPosition,
      position,
    });

    return <Banner {...size} params={params} onStub={handleStub} onError={handleError} />;
  }, [isHidden]);
});
