import * as React from 'react';

import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { CallButtonContainer } from './CallButtonContainer';
import { MortgageLink } from '../../../components/NewbuildingPromos/parts/PromoDetailsModal';
import { EPromoType, IPromoInfo } from '../../../types/newbuildingPromos';

interface ICallButtonContainerProps {
  mortgagePromoUrl: string;
  onMortgageClick?(): void;
  offer: IOfferSchema;
  promo: IPromoInfo;
  dataTestId?: string;
  placeType: string;
}

export const ActionButtonContainer: React.FC<ICallButtonContainerProps> = ({
  mortgagePromoUrl,
  onMortgageClick,
  offer,
  promo,
  dataTestId,
  placeType,
}) => {
  if (promo.promoType === EPromoType.CianMortgage) {
    return <MortgageLink url={mortgagePromoUrl} onClick={onMortgageClick} theme="fill_primary" />;
  }

  return <CallButtonContainer offer={offer} promo={promo} dataTestId={dataTestId} placeType={placeType} />;
};
