import { UIText2 } from '@cian/ui-kit';
import { TColors } from '@cian/ui-kit-design-tokens/colors';
import * as React from 'react';

import { NBSP } from 'shared/constants';

import * as styles from './PurchaseTermsOnboardingContent.css';

interface IPurchaseTermsOnboardingContentProps {
  color?: TColors;
}

export const PurchaseTermsOnboardingContent: React.FC<IPurchaseTermsOnboardingContentProps> = ({ color }) => {
  return (
    <div className={styles['content']}>
      <UIText2 color={color} whiteSpace="pre-line">
        Цена может зависеть от{NBSP}способа покупки — расскажем про все варианты
      </UIText2>
    </div>
  );
};
