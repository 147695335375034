import { Button } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { MORTGAGE_PROMO_TYPES } from 'shared/constants/newbuildingPromos';
import { PhoneContainer } from 'shared/containers/BottomAuthorBlockNew/PhoneContainer';
import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getDynamicCalltrackingSiteBlockId } from '../../../selectors/getDynamicCalltrackingSiteBlockId';
import { getGaLabel } from '../../../selectors/newObject';
import { IPromoInfo } from '../../../types/newbuildingPromos';
import { trackPhoneOpen, TTrackPhoneOpenAction } from '../../../utils/tracking/promos/trackPhoneOpen';
import { getCallButtonText } from '../helpers';

interface ICallButtonContainerProps {
  offer: IOfferSchema;
  promo: IPromoInfo;
  dataTestId?: string;
  placeType: string;
}

export const CallButtonContainer: React.FC<ICallButtonContainerProps> = ({ offer, promo, dataTestId, placeType }) => {
  const [phonesVisible, handlePhonesVisible] = React.useState(false);

  const siteBlockId = useSelector(getDynamicCalltrackingSiteBlockId);
  const gaLabel = useSelector(getGaLabel);

  const handleShowPhoneClick = React.useCallback(() => {
    handlePhonesVisible(true);
    let action: TTrackPhoneOpenAction = 'Open_card_promotions';
    if (MORTGAGE_PROMO_TYPES.includes(promo.promoType)) {
      action = 'Open_card_mortgage';
    }

    trackPhoneOpen({
      label: gaLabel,
      useLastProducts: false,
      action,
      offer,
      siteBlockId,
      promo,
      placeType,
    });
  }, [gaLabel, offer, promo, siteBlockId, placeType]);

  if (phonesVisible) {
    return <PhoneContainer placeType={placeType} />;
  }

  return (
    <Button data-testid={dataTestId} theme="fill_primary" size="M" onClick={handleShowPhoneClick}>
      {getCallButtonText(offer)}
    </Button>
  );
};
