import { ca } from '@cian/analytics';

import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IPageExtra } from '../../../types';
import { IPromoInfo } from '../../../types/newbuildingPromos';
import { getProducts } from '../../../utils/tracking/get_products';

export type TTrackPhoneOpenAction =
  | 'Open_promotions_block'
  | 'Open_card_promotions'
  | 'Open_card_mortgage'
  | 'Open_card_gallery';

export interface ITrackPhoneOpen {
  label: string;
  action: TTrackPhoneOpenAction;
  useLastProducts?: boolean;
  offer?: IOfferSchema;
  siteBlockId?: number | null;
  promo?: IPromoInfo;
  placeType?: string;
}

export const trackPhoneOpen = ({
  label,
  action,
  useLastProducts = true,
  offer,
  siteBlockId,
  promo,
  placeType,
}: ITrackPhoneOpen) => {
  const extra: Partial<IPageExtra> = {
    label: promo?.promoType,
    promoCianMortgage: promo?.uniquePromoId,
    promotion_id: promo?.promoId,
    is_price_with_discount: promo?.promoType === 'discount',
  };

  const products =
    !useLastProducts && offer ? getProducts({ agent: null, offer, kpId: null, siteBlockId, extra }) : null;

  ca('eventEbc', {
    category: 'Phones',
    action,
    label,
    page: {
      extra: {
        placeType: placeType === 'newbuildingPromo' ? 'main_price' : placeType,
      },
    },
    useLastProducts,
    ...(products != null && {
      products: [
        {
          ...products,
        },
      ],
    }),
  });
};
