import cn from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';

interface IOfferValuationProps {
  pricesBlock: React.ReactElement;
  chartFilters?: React.ReactElement | null;
  chart?: React.ReactElement | null;
  button?: React.ReactElement;
  singInButton?: React.ReactElement | null;
  adminBlock: React.ReactElement | null;
  isExperiment: boolean;
}

export const OfferValuationNew = React.forwardRef<HTMLDivElement, IOfferValuationProps>((props, ref) => {
  const { pricesBlock, chart, chartFilters, button, adminBlock, singInButton, isExperiment } = props;

  return (
    <div ref={ref}>
      {adminBlock}
      <div className={styles['prices_block']}>{pricesBlock}</div>
      {chartFilters && <div className={styles['chart_filters']}>{chartFilters}</div>}
      {chart && (
        <div className={cn(styles['chart_wrapper'], isExperiment && styles['chart_wrapper--blur'])}>
          <div className={cn(isExperiment && styles['chart_blur'])}>{chart}</div>
          {singInButton}
        </div>
      )}
      {button && <div className={styles['link_btn']}>{button}</div>}
    </div>
  );
});

OfferValuationNew.displayName = 'OfferValuationNew';
