import { OnboardingTooltip, Tooltip } from '@cian/ui-kit';
import * as React from 'react';

import { usePurchaseTermsOnboarding } from './hooks/usePurchaseTermsOnboarding';
import { PurchaseTermsOnboardingContent } from '../../components/PurchaseTermsOnboardingContent';

interface IPurchaseTermsOnboardingProps {
  isModalOpen: boolean;
}

export const PurchaseTermsOnboarding = ({
  children,
  isModalOpen,
}: React.PropsWithChildren<IPurchaseTermsOnboardingProps>) => {
  const { open, closeHandler } = usePurchaseTermsOnboarding({ isModalOpen });

  if (!open) {
    return (
      <Tooltip theme="white" placement="bottom" title={<PurchaseTermsOnboardingContent />}>
        {children as React.ReactElement}
      </Tooltip>
    );
  }

  return (
    <OnboardingTooltip
      theme="black"
      content={<PurchaseTermsOnboardingContent color="text-inverted-default" />}
      placement="bottom"
      open={true}
      onClose={closeHandler}
      flip={false}
      portal={false}
    >
      {children as React.ReactElement}
    </OnboardingTooltip>
  );
};
