import * as React from 'react';

import { IUserSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './Agreements.css';

interface IAgreementsProps {
  user: IUserSchema | null;
}
export const Agreements: React.FC<IAgreementsProps> = ({ user }) => {
  if (user && user.isAuthenticated) {
    return null;
  }

  return (
    <div className={styles['container']}>
      При подписке вы принимаете условия&nbsp;
      <a href="//www.cian.ru/help/about/rules/" target="_blank" rel="noreferrer">
        Пользовательского соглашения
      </a>{' '}
      и{' '}
      <a href="//www.cian.ru/help/about/36526/" target="_blank" rel="noreferrer">
        Политики конфиденциальности
      </a>
    </div>
  );
};
