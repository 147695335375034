import { Avatar, ModalWindow, UIHeading2, UIHeading4, UIText1, UIText2, UIText3 } from '@cian/ui-kit';
import * as React from 'react';

import { IPromoBank, IPromoFeature } from 'shared/types/newbuildingPromos';

import * as styles from './PromoDetailsModal.css';
import { BankItem } from './components/BankItem';
import { getBankLogoUrl } from './helpers';

export interface IPromoDetailsModalProps {
  authorDescription?: string | null;
  authorName?: string | null;
  authorLogoUrl?: string | null;
  contactsButton?: React.ReactNode;
  bank?: IPromoBank | null;
  banks: IPromoBank[] | null;
  description?: string | null;
  features?: IPromoFeature[] | null;
  mortgagePartnerLink: string | null;
  onClose(): void;
  onMortgageClick?(): void;
  open: boolean;
  title: string;
}

export const PromoDetailsModal: React.FC<React.PropsWithChildren<IPromoDetailsModalProps>> = ({
  authorLogoUrl,
  authorDescription,
  authorName,
  bank,
  banks,
  contactsButton,
  description,
  features,
  mortgagePartnerLink,
  onClose,
  onMortgageClick,
  open,
  title,
}) => {
  return (
    <ModalWindow open={open} fixed={false} width="100%" maxWidth={688} onClose={onClose} escape outside>
      <div className={styles['content']} data-testid="PromoDetailsModal">
        <div className={styles['header']}>
          <div className={styles['title']}>
            <UIHeading2 as="h2">{title}</UIHeading2>
            <UIText1>{authorName}</UIText1>
          </div>
          <Avatar size={56} src={authorLogoUrl} type="builder" data-testid="PromoAuthorAvatar" />
        </div>

        {features && !!features.length && (
          <div className={styles['features-list']}>
            {features.map(({ name, value }, idx) => (
              <div key={idx} className={styles['feature']}>
                <UIText3 display="block" color="gray60_100">
                  {name}
                </UIText3>
                <UIText1>{value}</UIText1>
              </div>
            ))}
          </div>
        )}
        {description && (
          <div className={styles['description']}>
            <UIText1 whiteSpace="pre-line">{description}</UIText1>
          </div>
        )}
        {banks && !!banks.length && (
          <div className={styles['banks']}>
            <div className={styles['banks-title']}>
              <UIHeading4>Застройщик работает с&nbsp;банками</UIHeading4>
            </div>
            <ul className={styles['banks-list']}>
              {banks.map(bank => (
                <li key={bank.id} className={styles['bank-item']}>
                  <BankItem logoUrl={getBankLogoUrl(bank)} name={bank.name} />
                </li>
              ))}
            </ul>
          </div>
        )}
        {bank && (
          <div className={styles['banks']}>
            <BankItem
              label="Банк"
              isPartner={bank.isPartner}
              logoUrl={getBankLogoUrl(bank)}
              mortgagePartnerLink={mortgagePartnerLink}
              name={bank.name}
              onMortgageClick={onMortgageClick}
            />
          </div>
        )}
        {authorDescription && (
          <div className={styles['author-description']}>
            <UIText2 color="gray60_100">{authorDescription}</UIText2>
          </div>
        )}
        <div className={styles['controls']}>{contactsButton}</div>
      </div>
    </ModalWindow>
  );
};
